import moment from "moment";

export const DateTime = (unixTime: number): string => {
  const date = new Date(unixTime * 1000); // Convert from seconds to milliseconds

  const options: Intl.DateTimeFormatOptions = {
    hour12: false, // Use 24-hour format
  };

  const localDateTime = date.toLocaleString(undefined, options);

  return localDateTime;
};

export function formatAmount(amount: number, step: string) {
  if (!amount) {
    return "";
  }
  const a =
    Math.floor(amount / parseFloat(step) + 0.000000000001) * parseFloat(step);
  return parseFloat(a.toFixed(8)).toLocaleString(undefined, {
    maximumFractionDigits: 20,
  });
}

export function formatAmountNumber(amount: number, step: string): number {
  if (!amount) {
    return 0; // You can return any default number here if needed
  }
  const calculatedValue =
    Math.floor(amount / parseFloat(step) + 0.000000000001) * parseFloat(step);
  const formattedValue = parseFloat(calculatedValue.toFixed(8));
  return formattedValue;
}

export function unix(date: string) {
  const date_format = "MMMM D, YYYY h:mm A";
  const date_object = moment(date, date_format);
  const unix_timestamp = date_object.unix();
  return unix_timestamp;
}

export function convertUnixToTimeAgo(unixTimestamp: string) {
  const currentTime = Date.now() / 1000;
  const timeDifference = currentTime - Number(unixTimestamp);

  if (timeDifference < 60) {
    return `${Math.floor(timeDifference)} seconds ago`;
  } else if (timeDifference < 3600) {
    return `${Math.floor(timeDifference / 60)} minutes ago`;
  } else if (timeDifference < 86400) {
    return `${Math.floor(timeDifference / 3600)} hours ago`;
  } else if (timeDifference < 604800) {
    return `${Math.floor(timeDifference / 86400)} days ago`;
  } else if (timeDifference < 2419200) {
    return `${Math.floor(timeDifference / 604800)} week ago`;
  } else {
    const date = new Date(Number(unixTimestamp) * 1000);
    return date.toLocaleString();
  }
}

export function convertScientificToDecimal(
  scientificNotation: string | number
) {
  const number = Number(scientificNotation);
  if (!isNaN(number)) {
    return number.toString(); // Converts it back to a normal string representation
  } else {
    // Handle invalid input
    return "Invalid input";
  }
}

export function changeAmount(amount: number, change: number, price: number) {
  const changedAmount = amount * (price - (100 * price) / (100 + change));
  return changedAmount;
}

export function extractRGB(rgbString: string): number[] | null {
  const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
  const match = rgbString.match(rgbRegex);

  if (match && match.length === 4) {
    const [, r, g, b] = match.map(Number);
    return [r, g, b];
  }

  return null;
}

export function rgbToHex(r: number, g: number, b: number): string {
  const rHex = r.toString(16).padStart(2, "0");
  const gHex = g.toString(16).padStart(2, "0");
  const bHex = b.toString(16).padStart(2, "0");

  return `#${rHex}${gHex}${bHex}`.toUpperCase();
}

// Combined function to extract RGB and convert to HEX
export function convertRGBToHex(rgbString: string): string | null {
  const rgbValues = extractRGB(rgbString);

  if (rgbValues) {
    const [r, g, b] = rgbValues;
    return rgbToHex(r, g, b);
  }

  return null;
}

export function darkenColor(hex: string, factor: number) {
  // Remove '#' if present
  hex = hex.replace("#", "");

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Darken the color by the factor
  r = Math.floor(Math.max(0, r * factor));
  g = Math.floor(Math.max(0, g * factor));
  b = Math.floor(Math.max(0, b * factor));

  // Convert RGB to hex
  const darkenedHex =
    "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  return darkenedHex;
}

export function stringToHTML(htmlString: string) {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlString, "text/html");
  return parsedHtml.body.childNodes;
}

export function getFormattedTimeFromUnix(unixTimestamp: string) {
  const date = new Date(Number(unixTimestamp) * 1000); // Convert seconds to milliseconds
  const hours = ("0" + date.getHours()).slice(-2); // Get hours in 24-hour format
  const minutes = ("0" + date.getMinutes()).slice(-2); // Get minutes

  return `${hours}:${minutes}`;
}

export function getFormattedDateFromUnix(unixTimestamp: string) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(Number(unixTimestamp) * 1000); // Convert seconds to milliseconds
  const monthIndex = date.getMonth(); // Get month index (0-11)
  const day = date.getDate(); // Get day of the month

  const monthName = months[monthIndex];
  const formattedDate = `${monthName} ${day}`;

  return formattedDate;
}

export function uriParser(uri: string) {
  if (uri.includes(":")) {
    const [network, rest] = uri.split(":");
    const [rawAddress, paramsString] = rest.split("?");

    let address: string;
    let chainId: string | undefined; // Declare chainId variable

    if (rawAddress.includes("@")) {
      const [addr, id] = rawAddress.split("@");
      address = addr;
      chainId = id;
    } else {
      address = rawAddress;
    }

    // Parse parameters into an object
    const params: { [key: string]: string } = {};

    if (paramsString) {
      const paramsArray = paramsString.split("&");
      paramsArray.forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = value;
      });
    }

    return { network, address, chainId, params };
  } else {
    return { address: uri };
  }
}

type resultType = {
  network?: string;
  address: string;
  chainId?: string | undefined;
  params?: {
    [key: string]: string;
  };
};

export const decodedUri = (uriParams: resultType): string => {
  const { network, address, chainId, params } = uriParams;

  // Check if network is falsy, return address
  if (!network) {
    if (address.length < 15) {
      return "error";
    }
    return `?address=${address}`;
  }

  // Check network type and construct URL accordingly
  if (network === "ethereum") {
    const isPolygon = chainId === "137";
    const isBSC = chainId === "56";
    const isBase = chainId === "8453";
    const networkSymbol = isPolygon
      ? "Polygon"
      : isBSC
      ? "BEP20"
      : isBase
      ? "Base"
      : "ERC20";
    const coinSymbol = isPolygon
      ? params?.token === "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"
        ? "USDT"
        : params?.token === "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063"
        ? "DAI"
        : "POL"
      : isBSC
      ? params?.token === "0x55d398326f99059ff775485246999027b3197955"
        ? "USDT"
        : params?.token === "0x2859e4544c4bb03966803b044a93563bd2d0dd4d"
        ? "SHIB"
        : "BNB"
      : params?.token === "0xdac17f958d2ee523a2206206994597c13d831ec7"
      ? "USDT"
      : params?.token === "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
      ? "USDC"
      : "ETH";
    const finalAmount = isPolygon
      ? params?.value
        ? String(Number(params.value) / 1e18)
        : undefined
      : params?.amount || params?.value;

    const url =
      `${coinSymbol}?address=${address}&network=${networkSymbol}` +
      (finalAmount !== undefined ? `&amount=${finalAmount}` : "");

    return url;
  } else if (network === "tron") {
    const coinSymbol =
      params?.token === "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t" ? "USDT" : "TRX";
    const url =
      `${coinSymbol}?address=${address}&network=TRC20` +
      (params?.amount !== undefined ? `&amount=${params.amount}` : "");
    return url;
  } else if (network === "bitcoin") {
    const url =
      `BTC?address=${address}&network=Bitcoin` +
      (params?.amount !== undefined ? `&amount=${params.amount}` : "");
    return url;
  } else if (network === "bitcoincash") {
    const url =
      `BCH?address=${address}&network=BitcoinCash` +
      (params?.amount !== undefined ? `&amount=${params.amount}` : "");
    return url;
  } else if (network === "dogecoin") {
    const url =
      `DOGE?address=${address}&network=Dogecoin` +
      (params?.amount !== undefined ? `&amount=${params.amount}` : "");
    return url;
  } else if (network === "litecoin") {
    const url =
      `LTC?address=${address}&network=Litecoin` +
      (params?.amount !== undefined ? `&amount=${params.amount}` : "");
    return url;
  } else if (network === "solana") {
    const url =
      `SOL?address=${address}&network=Solana` +
      (params?.amount !== undefined ? `&amount=${params.amount}` : "");
    return url;
  } else if (network === "digibyte") {
    const url =
      `DGB?address=${address}&network=DigiByte` +
      (params?.amount !== undefined ? `&amount=${params.amount}` : "");
    return url;
  } else if (network === "monero") {
    const url =
      `XMR?address=${address}&network=Monero` +
      (params?.amount !== undefined ? `&amount=${params.amount}` : "");
    return url;
  } else if (network === "ton") {
    const coinSymbol =
      params?.token === "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs"
        ? "USDT"
        : params?.token === "EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT"
        ? "NOT"
        : params?.token === "EQCvxJy4eG8hyHBFsZ7eePxrRsUQSFE_jpptRAYBmcG_DOGS"
        ? "DOGS"
        : "TON";

    let url = `${coinSymbol}?address=${
      address.split("//transfer/")[1] || address
    }&network=Ton`;
    const finalAmount = params?.amount
      ? String(Number(params.amount) / 1e9)
      : undefined;
    if (finalAmount !== undefined) {
      url += `&amount=${finalAmount}`;
    }
    return url;
  }

  // Handle the case where network is not recognized
  return "error";
};

export function shortenString(str: string, maxLength: number) {
  // Check if the string length is greater than the maximum length
  if (str.length > maxLength) {
    // Extract the first 14 characters
    let firstPart = str.slice(0, maxLength / 2);
    // Extract the last 14 characters
    let lastPart = str.slice(-maxLength / 2);
    // Combine the first and last parts with ...
    return firstPart + "..." + lastPart;
  } else {
    // If the string is within the maximum length, return the original string
    return str;
  }
}

export const emailRegex = /^([a-zA-Z0-9.]+@+[a-zA-Z]+(\.)+[a-zA-Z]{2,8})$/;
export const phoneRegex = /^(?:(?:\+|00)\d{1,3}[- ]?)?\d{10}$/;
